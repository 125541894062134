<template>
    <LiefengContent :isBack="true" @backClick="backClick">
        <template v-slot:title>积分模式定义</template>
        <template v-slot:toolsbarLeft>
            <Input :maxlength="20" v-model.trim="searchModel.poolName" placeholder="模式名称" style="width: 150px; margin-right: 10px" />
            <!-- <Select v-model="searchModel.model" style="width: 120px; margin-right: 5px" placeholder="全部模式" clearable>
                <Option :value="item.dictKey" v-for="(item, index) in selectList" :key="index">{{ item.dictValue }}</Option>
            </Select> -->
            <Button
                type="primary"
                @click="
                    () => {
                        getList()
                    }
                "
            >
                查询
            </Button>
            <Button
                type="info"
                style="margin-left: 10px"
                @click="
                    () => {
                        searchModel = {
                            poolName: '',
                        }
                        getList()
                    }
                "
            >
                重置
            </Button>
        </template>
        <template v-slot:toolsbarRight>
            <Button type="info" @click="append({})">新增</Button>
        </template>
        <template v-slot:contentArea>
            <div class="leftMenu">
                <LiefengTable
                    :talbeColumns="tableColumns"
                    :tableData="menuList"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                ></LiefengTable>
            </div>

            <LiefengModal :title="currentFormData.poolId ? '修改模式' : '新增模式'" width="30%" height="350px" :value="addModal.status" @input="addModalData" class="export-modal">
                <template v-slot:contentarea>
                    <Form :model="currentFormData" :label-width="120" :rules="validateForm" class="form" ref="form">
                        <FormItem label="模式名称" prop="poolName">
                            <Input placeholder="请输入名称" v-model.trim="currentFormData.poolName"></Input>
                        </FormItem>
                        <FormItem label="描述" prop="poolDesc">
                            <Input type="textarea" v-model.trim="currentFormData.poolDesc" placeholder="简单描述" maxlength="500"></Input>
                        </FormItem>
                        <FormItem label="图标">
                            <LiefengUpload
                                ref="LiefengUploadIcon"
                                v-if="showImage"
                                accept=".jpg,.png,.gif,.jpeg"
                                :format="['jpg', 'png', 'jpeg', 'gif']"
                                :defaultList="currentFormData.poolIcon"
                                :showView="true"
                                :multiple="true"
                                :fileLength="1"
                            ></LiefengUpload>
                        </FormItem>
                    </Form>
                </template>
                <template #toolsbar>
                    <Button type="info" style="margin-right: 10px" @click="addModalData(false)">取消</Button>
                    <Button type="primary" icon="ios-share-outline" @click="submit">保存</Button>
                </template>
            </LiefengModal>
            <!-- 抽奖设置 -->
            <LiefengModal :value="priceModal.status" @input="priceModalData" :title="'等级设置'" :fullscreen="false" :width="1000" height="500px">
                <template v-slot:contentarea>
                    <PriceModal @success="setPrice" ref="priceModal" v-if="priceModal.status" :priceData="priceData"></PriceModal>
                </template>
                <template #toolsbar>
                    <Button type="info" style="margin-right: 10px" @click="priceModalData(false)">取消</Button>
                    <Button type="primary" icon="ios-share-outline" @click="priceSubmit">保存</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
//@route('/test15minmanage')
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import LiefengUpload from "@/components/LiefengUpload"
import PriceModal from "../components/priceModal"
export default {
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
        LiefengUpload,
        PriceModal,
    },
    data() {
        return {
            selectList: [
                {
                    dictValue: "福利金",
                    dictKey: "1",
                },
                {
                    dictValue: "流量池",
                    dictKey: "2",
                },
                {
                    dictValue: "权益值",
                    dictKey: "3",
                },
            ],
            searchModel: {
                poolName: "",
            },
            page: 1,
            pageSize: 20,
            total: 0,
            loading: false,
            menuList: [],
            tableColumns: [
                {
                    title: "积分模式名称",
                    width: 250,
                    key: "poolName",
                    align: "center",
                },
                {
                    title: "描述",
                    minWidth: 200,
                    key: "poolDesc",
                    align: "center",
                },
                {
                    title: "图标",
                    minWidth: 200,
                    key: "creatorName",
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    padding: "10px 0",
                                    display: params.row.poolIcon ? "block" : "none",
                                    // justifyContent:'center'
                                    textAlign: "center",
                                },
                            },
                            [
                                h("img", {
                                    style: {
                                        width: "80px",
                                        height: "80px",
                                    },
                                    attrs: {
                                        src: params.row.poolIcon,
                                    },
                                }),
                                h(
                                    "span",
                                    {
                                        style: {
                                            display: params.row.poolIcon ? "" : "none",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                            marginLeft: "10px",
                                        },
                                        on: {
                                            click: async () => {
                                                this.currentFormData = {
                                                    poolId: params.row.poolId,
                                                    poolDesc: params.row.poolDesc,
                                                    poolName: params.row.poolName,
                                                    poolIcon: params.row.poolIcon && params.row.poolIcon != "" ? [{ url: params.row.poolIcon, name: "" }] : [],
                                                }
                                                this.showImage = false
                                                this.$nextTick(() => {
                                                    this.showImage = true
                                                })
                                                this.addModalData(true)
                                            },
                                        },
                                    },
                                    "更换"
                                ),
                            ]
                        )
                    },
                },
                {
                    title: "启用状态",
                    key: "gmtCreate",
                    width: 170,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h("i-switch", {
                                props: {
                                    value: params.row.poolStatus,
                                    size: "large",
                                    "true-value": "1",
                                    "false-value": "2",
                                },
                                scopedSlots: {
                                    open: () => h("span", "启用"),
                                    close: () => h("span", "禁用"),
                                },
                                on: {
                                    "on-change": val => {
                                        this.currentFormData = {
                                            poolId: params.row.poolId,
                                            poolDesc: params.row.poolDesc,
                                            poolName: params.row.poolName,
                                            poolIcon: params.row.poolIcon,
                                        }
                                        this.changeSwitch(val)
                                    },
                                },
                            }),
                        ])
                    },
                },
                {
                    title: "操作",
                    align: "center",
                    width: 200,
                    fixed: "right",
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    textAlign: "center",
                                },
                            },
                            [
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: async () => {
                                                this.currentFormData = {
                                                    poolId: params.row.poolId,
                                                    poolDesc: params.row.poolDesc,
                                                    poolName: params.row.poolName,
                                                    poolIcon: params.row.poolIcon && params.row.poolIcon != "" ? [{ url: params.row.poolIcon, name: "" }] : [],
                                                }
                                                this.showImage = false
                                                this.$nextTick(() => {
                                                    this.showImage = true
                                                })
                                                this.addModalData(true)
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "修改" //1001
                                ),
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: async () => {
                                                this.poolId = params.row.poolId
                                                this.priceData = {}
                                                await this.$get("/gateway/sy-points/api/sypointes/pool/listLevelRuleByPoolId", {
                                                    poolId: this.poolId,
                                                }).then(res => {
                                                    this.priceData = {
                                                        poolId: this.poolId,
                                                        pointsLevelBos: res.dataList || [],
                                                    }
                                                    this.priceModalData(true)
                                                })
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "等级设置" //1001
                                ),
                                // h(
                                //     "span",
                                //     {
                                //         on: {
                                //             click: async () => {
                                //                 this.deleteData(params.row.poolId)
                                //             },
                                //         },
                                //         style: {
                                //             width: "65px",
                                //             marginRight: "10px",
                                //             textAlign: "center",
                                //             color: "#2d8cf0",
                                //             cursor: "pointer",
                                //             userSelect: "none",
                                //         },
                                //     },
                                //     "删除" //1001
                                // ),
                            ]
                        )
                    },
                },
            ],
            currentFormData: {},
            validateForm: {
                poolName: [{ required: true, message: "请输入主题", trigger: "blur" }],
                poolDesc: [{ required: true, message: "请输入备注说明", trigger: "blur" }],
            },

            addModal: {
                status: false,
            },
            priceModal: {
                status: false,
            },
            poolId: "",
            type: "add",

            showImage: false,
        }
    },
    methods: {
        backClick() {
            var index = parent.layer.getFrameIndex(window.name)
            if (index) {
                //先得到当前iframe层的索引
                parent.layer.close(index) //再执行关闭
            } else {
                this.$router.push({
                    path: "/mallhomepage",
                })
            }
        },
        //   切换状态
        changeSwitch(poolStatus) {
            this.$post(
                "/gateway/sy-points/api/sypointes/pool/updatePool",
                {
                    ...this.currentFormData,
                    poolStatus,
                },
                { "content-type": "application/json" }
            )
                .then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            background: true,
                            content: "操作成功",
                        })
                        this.currentFormData = {}
                        this.getList(this.page)
                    } else {
                        this.$Message.error({
                            background: true,
                            content: res.poolDesc,
                        })
                    }
                })
                .catch(err => {
                    this.$Message.error({
                        background: true,
                        content: "操作失败",
                    })
                })
        },
        // 获取奖品模态框数据
        setPrice(val) {
            if (val) {
                this.$post(
                    "/gateway/sy-points/api/sypointes/pool/saveLevel",
                    {
                        ...val,
                        pointsLevelBos: (() => {
                            return val.pointsLevelBos.reverse().map((item, index) => {
                                return {
                                    ...item,
                                    levelSort: index,
                                }
                            })
                        })().reverse(),
                        poolId: this.poolId,
                    },
                    { "Content-Type": "application/json" }
                ).then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            content: "操作成功",
                            background: true,
                        })
                        this.priceModalData(false)
                        return
                    }
                })
            }
        },
        // 奖品设置确定接口
        priceSubmit() {
            this.$refs.priceModal.sumbit()
        },
        addModalData(status) {
            this.addModal.status = status
        },
        priceModalData(status) {
            this.priceModal.status = status
        },

        submit() {
            this.currentFormData.poolIcon = (() => {
                if (this.$refs.LiefengUploadIcon.uploadList && this.$refs.LiefengUploadIcon.uploadList.length) {
                    let list = []
                    this.$refs.LiefengUploadIcon.uploadList.map(item => {
                        list.push(item.url)
                    })
                    return list && list.length ? list.join(",") : ""
                } else {
                    return ""
                }
            })()
            this.$refs.form.validate(status => {
                if (status) {
                    var cFormData = JSON.parse(JSON.stringify(this.currentFormData))
                    var url = ""
                    if (cFormData.poolId && cFormData.poolId != "") {
                        // 修改
                        url = "/gateway/sy-points/api/sypointes/pool/updatePool"
                    } else {
                        // 新增
                        url = "/gateway/sy-points/api/sypointes/pool/addPool"
                    }
                    this.$post(
                        url,
                        {
                            ...cFormData,
                            oemCode: parent.vue.oemInfo.oemCode,
                            poolType: 1,
                        },
                        { "content-type": "application/json" }
                    )
                        .then(res => {
                            this.addModalData(false)
                            if (res.code == 200) {
                                this.$Message.success({
                                    background: true,
                                    content: "保存成功",
                                })
                                this.currentFormData = {}
                                this.getList(this.page)
                            } else {
                                this.$Message.error({
                                    background: true,
                                    content: res.poolDesc,
                                })
                            }
                        })
                        .catch(err => {
                            console.log(err)
                            this.$Message.error({
                                background: true,
                                content: "保存失败，请联系管理员处理",
                            })
                        })
                }
            })
        },

        append(data) {
            this.currentFormData = {
                poolId: data.poolId,
                poolName: "",
                poolDesc: "",
                poolIcon: [],
            }
            this.showImage = false
            this.$nextTick(() => {
                this.showImage = true
            })

            this.addModalData(true)
        },
        deleteData(poolId) {
            this.$Modal.confirm({
                title: "删除选项",
                content: "删除后无法恢复，是否继续删除？",
                okText: "确认删除",
                cancelText: "取消",
                onOk: () => {
                    return
                    this.$post("", {
                        poolId,
                    }).then(res => {
                        var that = this
                        if (res && res.code == 200) {
                            this.$Message.success({
                                content: "删除成功",
                                background: true,
                            })
                            that.getList()
                            return
                        }
                    })
                },
            })
        },
        hadlePageSize(obj) {
            this.pageSize = obj.pageSize
            this.page = obj.page
            this.getList()
        },
        getList() {
            this.loading = true
            this.$get("/gateway/sy-points/api/sypointes/pool/queryPoolByPage", {
                page: this.page,
                pageSize: this.pageSize,
                ...this.searchModel,
            })
                .then(res => {
                    if (res.code == 200 && res.dataList) {
                        this.menuList = res.dataList
                        this.page = res.currentPage
                        this.total = res.maxCount
                    }
                    this.$Message.destroy()
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    this.$Message.destroy()
                    this.$Message.error({
                        background: true,
                        content: "获取数据失败，请联系管理员处理",
                    })
                })
        },
    },
    async created() {
        this.getList()
    },
}
</script>

<style lang='less'>
/deep/.ivu-form-item-content {
    display: flex !important;
}
.ivu-form-item-label {
    display: flex !important;
}
</style>
